import Prism from 'prismjs';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism.css';

import ClipboardJS from 'clipboard/src/clipboard.js';

export default (function () {

    $(function(){
        window.prismjs = {};
        prismjs = {
            init: function () {
                this.highlightCode();
            },
            highlightCode: function () {
                let $codeElements = $('code[class^="language-"]');
                $.each($codeElements, function (index, codeElement) {
                    Prism.highlightElement(codeElement);
                });
            },
        };

        function isPrismClass(preTag) {
            return preTag.className.substring(0, 8) === 'language';
        }

        // 2. 開閉
        $('.js-show-code').click(function () {
            if ($(this).find('use').attr('js-is-open') != 'true') {
                $(this).find('use').attr('js-is-open', 'true');
                $(this)
                    .find('use')
                    .attr('xlink:href', '/assets/img/bootstrap-icons.svg#code');
            } else {
                $(this).find('use').attr('js-is-open', 'false');
                $(this)
                    .find('use')
                    .attr('xlink:href', '/assets/img/bootstrap-icons.svg#code-square');
            }
        });

        // 3. クリップボード
        const clipboard = new ClipboardJS('.copy', {
            target: (trigger) => {
                return trigger.nextElementSibling;
            },
        });

        clipboard.on('success', (event) => {
            event.trigger.textContent = 'copied!';
            setTimeout(() => {
                event.trigger.textContent = 'copy';
                event.clearSelection();
                event.trigger.className = 'copy';
            }, 2000);
        });

    });

})();
