import PerfectScrollbar from 'perfect-scrollbar';
export default (function () {

    $(function () {

        // Sidebar Current Page Active
        const $sidebarLv1List = $('.js-sidebar-index');
        const $sidebarLv1Items = $sidebarLv1List.find('.nav-item');

        $sidebarLv1Items.each(function () {
            const $sidebarLv1Item = $(this);
            const $sidebarLv1Anchor = $sidebarLv1Item.find('.nav-link');
            const $sidebarLv1AnchorIcon = $sidebarLv1Anchor.find('.svg-inline--fa');
            const $sidebarLv2List = $sidebarLv1Item.find('.js-sidebar-child');
            const $sidebarLv2Items = $sidebarLv2List.children('li');

            // lv2 scan
            $sidebarLv2Items.each(function () {
                const $sidebarLv2Item = $(this);
                const $sidebarLv2Anchor = $sidebarLv2Item.children('a');
                const $sidebarLv2AnchorIcon = $sidebarLv1Anchor.find('.svg-inline--fa');

                if ($sidebarLv2Anchor.prop('href') === location.href) {
                    // lv2 hit
                    // lv2 activate
                    $sidebarLv1Item.addClass('active-select');
                    $sidebarLv1AnchorIcon.removeClass('rotate-90').addClass('rotate-270');
                    $sidebarLv2Anchor.addClass('active-select');
                    $sidebarLv2List.show();
                }

                const $sidebarLv3List = $sidebarLv2Item.find('.js-sidebar-child-sub');
                const $sidebarLv3Items = $sidebarLv3List.children('li');

                // lv3 scan
                $sidebarLv3Items.each(function () {
                    const $sidebarLv3Item = $(this);
                    const $sidebarLv3Anchor = $sidebarLv3Item.children('a');

                    if ($sidebarLv3Anchor.prop('href') === location.href) {
                        // lv3 hit
                        // lv2 activate
                        $sidebarLv1Item.addClass('active-select');
                        $sidebarLv1AnchorIcon.removeClass('rotate-90').addClass('rotate-270');
                        $sidebarLv2Anchor.addClass('active-select');
                        $sidebarLv2List.show();

                        // lv3 activate
                        $sidebarLv2Item.addClass('active-select');
                        $sidebarLv2AnchorIcon.removeClass('rotate-90').addClass('rotate-270');
                        $sidebarLv3Anchor.addClass('active-select');
                        $sidebarLv3List.show();
                    }
                });
            });
        });

        // Sidebar Parent Switch Dropdown.
        $('.js-tgt').on('click', function () {
            const $sidebarLv1Anchor = $(this);

            if ($sidebarLv1Anchor.attr('href') !== '#') {
                return;
            }

            const $sidebarLv1Item = $sidebarLv1Anchor.closest('.nav-item');
            const $sidebarLv1AnchorIcon = $sidebarLv1Anchor.find('.svg-inline--fa');
            const $sidebarLv2List = $sidebarLv1Anchor.next('.js-sidebar-child');

            if ($sidebarLv2List.is(':visible')) {
                // Close
                $sidebarLv1Item.removeClass('active-select');
                $sidebarLv1AnchorIcon.removeClass('rotate-270').addClass('rotate-90');
                $sidebarLv2List.slideUp();
            } else {
                // Open
                $sidebarLv1Item.addClass('active-select');
                $sidebarLv1AnchorIcon.removeClass('rotate-90').addClass('rotate-270');
                $sidebarLv2List.slideDown();
            }

            return false;
        });

        $('.js-tgt-sub').on('click', function () {
            const $sidebarLv2Anchor = $(this);

            if ($sidebarLv2Anchor.attr('href') !== '#') {
                return;
            }

            const $sidebarLv2Item = $sidebarLv2Anchor.closest('.nav-item-sub');
            const $sidebarLv2AnchorIcon = $sidebarLv2Anchor.find('.svg-inline--fa');
            const $sidebarLv3List = $sidebarLv2Anchor.next('.js-sidebar-child-sub');

            if ($sidebarLv3List.is(':visible')) {
                // Close
                $sidebarLv2Item.removeClass('active-select');
                $sidebarLv2AnchorIcon.removeClass('rotate-270').addClass('rotate-90');
                $sidebarLv3List.slideUp();
            } else {
                // Open
                $sidebarLv2Item.addClass('active-select');
                $sidebarLv2AnchorIcon.removeClass('rotate-90').addClass('rotate-270');
                $sidebarLv3List.slideDown();
            }

            return false;
        });

        // Scrollbar
        const scrollbarClasses = [
            '.js-sidebar-wrapper',
            '.js-notification-wrapper',
            '.c-search-wrapper-scroll',
            '.c-sp-wrapper',
            '.map-main__config-body',
            '.table-responsive'
        ];
        scrollbarClasses.forEach(function (scrollbarClass) {
            $(scrollbarClass).each(function () {
                const ps = new PerfectScrollbar(this);
            });
        });

        // Input switch (text/password)
        $('.eye-btn').on('click', function () {
            const $input = $(this).prev('input');
            const $icon = $(this).find('use');

            if ($input.attr('type') == 'password') {
                $input.attr('type', 'text');
                $icon.attr('xlink:href', '/assets/img/bootstrap-icons.svg#eye');
            } else {
                $input.attr('type', 'password');
                $icon.attr('xlink:href', '/assets/img/bootstrap-icons.svg#eye-slash');
            }
        });

        // Main area fadein
        var $main = $('.js-content');
        var beforfeScale = 0.99;
        var afterScale = 1;

        $main.animate(
            {
                'opacity': 1,
            },
            {
                duration: 300,
                easing: 'swing',
                step: function (n) {
                    var scale = beforfeScale + (afterScale - beforfeScale) * n;
                    $(this).css({
                        '-webkit-transform': 'scale(' + scale + ')',
                        'transform': 'scale(' + scale + ')',
                    });
                },
                complete: function () {
                    $(this).css({
                        'opacity': '',
                        '-webkit-transform': '',
                        'transform': '',
                    });
                },
            }
        );

        (function () {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            var forms = document.getElementsByClassName('needs-validation');
            // Loop over them and prevent submission
            var validation = Array.prototype.filter.call(forms, function (form) {
                form.addEventListener('submit', function (event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    form.classList.add('was-validated');

                    // bootstrap-select validation
                    var selectElems = form.querySelectorAll('.bootstrap-select select');
                    var selectArr = Array.prototype.slice.call(selectElems);
                    selectArr.forEach(function (selectElem) {
                        var evt = document.createEvent('HTMLEvents');
                        evt.initEvent('initValidation', true, true);
                        return selectElem.dispatchEvent(evt);
                    });
                }, false);

                // checkbox group required
                var onCheckboxClick = function (event) {
                    var groupCheckboxElems = document.getElementsByName(event.target.name);
                    var groupCheckboxArr = Array.prototype.slice.call(groupCheckboxElems);

                    var checkedLength = groupCheckboxArr.filter(function (checkboxElem) {
                        return checkboxElem.checked;
                    }).length;
                    var isRequredOn = checkedLength === 0;

                    groupCheckboxArr.forEach(function (checkboxElem) {
                        checkboxElem.required = isRequredOn;
                    });
                };
                var checkboxElems = form.querySelectorAll('input[type="checkbox"][required]');
                var checkboxArr = Array.prototype.slice.call(checkboxElems);
                checkboxArr.forEach(function (checkboxElem) {
                    checkboxElem.addEventListener('click', onCheckboxClick);
                });

                // checkbox group validation
                $(form).find('.checkbox-group').each(function () {
                    var $wrapper = $(this);
                    var $checkboxes = $wrapper.find('[type="checkbox"],[type="radio"]');
                    if (!$checkboxes.length) {
                        return true;
                    }
                    var isValid = $checkboxes.get(0).checkValidity();
                    $wrapper.toggleClass('is-valid', isValid).toggleClass('is-invalid', !isValid);
                });
            });

            // checkbox group validation
            $(forms).find('.checkbox-group').each(function () {
                var $wrapper = $(this);
                var $checkboxes = $wrapper.find('[type="checkbox"],[type="radio"]');
                if (!$checkboxes.length) {
                    return true;
                }
                $checkboxes.on('change', function () {
                    var isValid = this.checkValidity();
                    $wrapper.toggleClass('is-valid', isValid).toggleClass('is-invalid', !isValid);
                });
            });
        })();

    });

})();
