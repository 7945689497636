export default (function () {

    $(function(){

        $('.js-header-sp .area-toggler').click(function() {

            if ($(this).hasClass('opened')) {

                // PC版のJSアクション付与クラスを事前に削除
                reset_pc_minimize();

                const setAnimateSec = 300;

                $(this).removeClass('opened');

                $('.js-sidebar-wrapper').removeClass('js-set-sidebar-open-sp');

                $('.js-sidebar').animate({
                    width: '0%'
                }, setAnimateSec, '', function() {
                    $('.js-sidebar')
                        .css('width', '')
                        .removeClass('js-set-sidebar-open-sp');
                });

                $('.close-btn').animate({
                    opacity: 'hide'
                }, setAnimateSec, '', function() {
                    $('.open-btn').animate({
                        opacity: 'show'
                    });
                });

            } else {

                // PC版のJSアクション付与クラスを事前に削除
                reset_pc_minimize();

                const setAnimateSec = 300;

                $(this).addClass('opened');

                $('.js-sidebar').animate({
                    width: '100%'
                }, setAnimateSec, '', function() {
                    $('.js-sidebar')
                        .css('width', '')
                        .addClass('js-set-sidebar-open-sp');
                });

                // 遅延表示で崩れを防ぐ
                setTimeout(function(){
                    $('.js-sidebar-wrapper').animate({
                        opacity: 'show'
                    }, setAnimateSec, '', function() {
                        $('.js-sidebar-wrapper')
                            .css('display', '')
                            .addClass('js-set-sidebar-open-sp');
                    });

                }, setAnimateSec);

                $('.open-btn').animate({
                    opacity: 'hide'
                }, setAnimateSec, '', function() {
                    $('.close-btn').animate({
                        opacity: 'show'
                    });
                });
            }
        });

        // PC サイドバーToggleをリセット
        function reset_pc_minimize() {
            $('.js-set-sidebar-minimize').removeClass('js-set-sidebar-minimize');
            $('.js-set-main-panel-minimize').removeClass('js-set-main-panel-minimize');
            $('.js-set-navbar-minimize')
                .addClass('navbar-absolute')
                .removeClass('js-set-navbar-minimize');

            $('.js-sidebar .js-sidebar-open.h1').removeClass('js-set-sidebar-hide')
            $('.js-sidebar .js-sidebar-open.js-sidebar-child > li').removeClass('js-set-sidebar-hide');
            $('.js-sidebar .js-tgt .js-sidebar-open').removeClass('js-set-sidebar-hide')
            $('.js-sidebar .sidebar-sticky').removeClass('js-set-sidebar-minimize-sticky');

            $('.js-sidebar .js-sidebar-open.sidebar-footer').show()
        }

        // [SP footer] Home
        $('.js-footer-sp .home-btn').click(function() {

            // $('.content').show();

            if ($(this).hasClass('active') == false) {
                $('.js-notification-wrapper').animate({
                    height: 0
                });
                $('.js-search-wrapper').animate({
                    height: 0
                });
            }

            $(this).addClass('active');
            $('.js-footer-sp .search-btn').removeClass('active');
            $('.js-footer-sp .bell-btn').removeClass('active');
        });

        // [SP footer] Search
        $('.js-footer-sp .search-btn').click(function() {

            // header(80px) - footer(60px)
            const newHeight     = innerHeight - 80 - 60;
            const setAnimateSec = 300;

        if ($(this).hasClass('active')) {

            // $('.content').show();

            // Active Me...
            $('.js-search-wrapper').animate({
                height: 0
            }, setAnimateSec, '', function() {
                $(this).css('height', '').css('display', 'none').removeClass('c-sp-wrapper');
            });

            $(this).removeClass('active');

        } else if ($('.js-notification-wrapper').is(':visible')) {

            // Active Other...
            // $('.js-notification-wrapper').removeClass('c-sp-wrapper');

            // $('.js-search-wrapper')
            //     .css('height', 'calc(100% - 140px)')
            //     .fadeIn(setAnimateSec);

            // setTimeout(function(){
            //     $('.js-search-wrapper').css('display', '').addClass('c-sp-wrapper');
            // }, setAnimateSec);

            $('.js-notification-wrapper').animate({
                opacity: 0
            }, {
                duration: setAnimateSec,
                step: function(n) {
                    $(this).css({
                        '-webkit-transform': 'translateX(' + (100 - n * 100) + '%)',
                        'transform': 'translateX(' + (100 - n * 100) + '%)'
                    });
                }
            });

            $('.js-search-wrapper').addClass('c-sp-wrapper').css({
                opacity: 0,
                height: 'calc(100% - 140px)'
            }).show().animate({
                opacity: 1
            }, {
                duration: setAnimateSec,
                step: function(n) {
                    $(this).css({
                        '-webkit-transform': 'translateX(' + -(100 - n * 100) + '%)',
                        'transform': 'translateX(' + -(100 - n * 100) + '%)'
                    });
                }
            });

            $(this).addClass('active');

            setTimeout(function(){
                $('.js-notification-wrapper')
                    .css({
                        '-webkit-transform': '',
                        'transform': '',
                        'height': '',
                        'opacity': ''
                    })
                    .removeClass('c-sp-wrapper');

                $('.js-search-wrapper')
                    .css({
                        '-webkit-transform': '',
                        'transform': '',
                        'opacity': ''
                    })
            }, setAnimateSec);

            $(this).removeClass('active');

        } else {

                // Nothing Active...
                $('.js-search-wrapper').show().animate({
                    height: newHeight
                }, setAnimateSec, '', function() {
                    // $('.content').hide();
                    $('.js-search-wrapper')
                        .css('display', '')
                        .css('height', 'calc(100% - 140px)')
                        .addClass('c-sp-wrapper');
                });

            $(this).addClass('active');

            }

            $('.js-footer-sp .home-btn').removeClass('active');
            $('.js-footer-sp .bell-btn').removeClass('active');
        });

        // [SP Footer] Notification
        $('.js-footer-sp .bell-btn').click(function() {
            // header(80px) - footer(60px)
            const newHeight = innerHeight - 80 - 60;
            const setAnimateSec = 300;

        if ($(this).hasClass('active')) {

            // $('.content').show(0);

            // Active Me...
            $('.js-notification-wrapper').animate({
                height: 0
            }, setAnimateSec, '', function() {
                $('.js-notification-wrapper')
                    .css('height', '')
                    .removeClass('c-sp-wrapper');
            });

            $(this).removeClass('active');

        } else if ($('.js-search-wrapper').is(':visible')) {

            // Active Other...
            // $('.js-search-wrapper').removeClass('c-sp-wrapper');

            // $('.js-notification-wrapper')
            //     .css('height', 'calc(100% - 140px)')
            //     .fadeIn(setAnimateSec);

            // setTimeout(function(){
            //     $('.js-notification-wrapper')
            //         .css('display', '')
            //         .addClass('c-sp-wrapper');
            // }, setAnimateSec);


            $('.js-search-wrapper').animate({
                opacity: 0
            }, {
                duration: setAnimateSec,
                step: function(n) {
                    $(this).css({
                        '-webkit-transform': 'translateX(' + -(100 - n * 100) + '%)',
                        'transform': 'translateX(' + -(100 - n * 100) + '%)'
                    });
                }
            });

            $('.js-notification-wrapper').addClass('c-sp-wrapper').css({
                opacity: 0,
                height: 'calc(100% - 140px)'
            }).show().animate({
                opacity: 1
            }, {
                duration: setAnimateSec,
                step: function(n) {
                    $(this).css({
                        '-webkit-transform': 'translateX(' + (100 - n * 100) + '%)',
                        'transform': 'translateX(' + (100 - n * 100) + '%)'
                    });
                }
            });

            $(this).addClass('active');

            setTimeout(function() {
                $('.js-search-wrapper')
                    .css({
                        '-webkit-transform': '',
                        'transform': '',
                        'height': '',
                        'opacity': ''
                    }).removeClass('c-sp-wrapper');

                $('.js-notification-wrapper')
                    .css({
                        '-webkit-transform': '',
                        'transform': '',
                        'opacity': ''
                    });
            }, setAnimateSec);

            $(this).removeClass('active');

        } else {

                // Nothing Active...
                $('.js-notification-wrapper').show().animate({
                    height: newHeight
                }, setAnimateSec, '', function() {
                    // $('.content').hide();
                    $('.js-notification-wrapper')
                        .css('display', '')
                        .css('height', 'calc(100% - 140px)')
                        .addClass('c-sp-wrapper');
                });

            $(this).addClass('active');

            }

            $('.js-footer-sp .home-btn').removeClass('active');
            $('.js-footer-sp .search-btn').removeClass('active');
        });

        // SP Google Map
        $('.js-map-main').each(function() {
            const $root = $(this);
            const $configPanel = $root.find('.js-map-config');
            const $configPanelToggle = $root.find('.js-map-sp-toggle');
            const $configPanelMore = $root.find('.js-map-sp-config-more');

            const setAnimateSec = 300;
            const mapPanelMinHeight = 30;
            const configPanelMinHeightOffset = -1;

            let rootHeight;
            let configPanelMinHeight;
            let isConfigPanelOpen = false;

            $configPanelToggle.on('click', function() {
                if (isConfigPanelOpen) {
                    $configPanel.animate({
                        'height': configPanelMinHeight
                    }, setAnimateSec);
                    isConfigPanelOpen = false;
                } else {
                    $configPanel.animate({
                        'height': (rootHeight - mapPanelMinHeight)
                    }, setAnimateSec);
                    isConfigPanelOpen = true;
                }
            });

            // ブラウザリサイズ時処理
            var latestMediaType = null;
            var onResize = function() {
                var isMediaXl = (window.innerWidth >= 1200);
                var isMediaMd = (window.innerWidth >= 768);
                var currentMediaType = isMediaXl ? 'xl' : isMediaMd ? 'md' : '';

                rootHeight = $root.outerHeight();

                // SP/PC切り替え時 高さ切り替え
                if (currentMediaType === 'xl' || currentMediaType === 'md') {
                    $configPanel.css({
                        'height': ''
                    });
                } else {
                    configPanelMinHeight = ($configPanelMore.offset().top - $configPanel.offset().top + configPanelMinHeightOffset);
                    if (isConfigPanelOpen) {
                        $configPanel.css({
                            'height': (rootHeight - mapPanelMinHeight)
                        }, setAnimateSec);
                    } else {

                        $configPanel.css({
                            'height': configPanelMinHeight
                        }, setAnimateSec);
                    }
                }

                latestMediaType = currentMediaType;
            };
            $(window).on('load resize', function() {
                window.requestAnimationFrame(onResize);
            });
            onResize();
        })

    });

})();
