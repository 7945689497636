export default (function () {

    $(function(){

        // [Sidebar Toggle]

        var toggleSidebar = function (isActive) {

            if (!isActive) {

                // SP版のJSアクション付与クラスを事前に削除
                $('.js-sidebar').removeClass('js-set-sidebar-open-sp');
                $('.js-sidebar-wrapper').removeClass('js-set-sidebar-open-sp');

                const setTimeoutSec = 300;
                const calcBaseWidth = 280;
                const resizeWidth = $('body').width() - calcBaseWidth;

                $('body').removeClass('aside-minimize')

                $('.main-panel').animate({
                    width: resizeWidth,
                }, setTimeoutSec, '', function() {
                    // js-set-main-panel-minimize => minimize.scss
                    $('.main-panel')
                        .css('width', '')
                        .removeClass('js-set-main-panel-minimize');
                });

                $('.js-sidebar').animate({
                    width: calcBaseWidth
                }, setTimeoutSec, '', function() {
                    $('.js-sidebar')
                        .css('width', '')
                        .removeClass('js-set-sidebar-minimize');
                });

                //$('.navbar.navbar-absolute').removeClass('js-set-navbar-minimize');
                $('.navbar.js-set-navbar-minimize').animate({
                    width: resizeWidth,
                    left: calcBaseWidth
                }, setTimeoutSec, '', function() {
                    $('.navbar.js-set-navbar-minimize')
                        .css('width', '')
                        .css('left', '')
                        .addClass('navbar-absolute')
                        .removeClass('js-set-navbar-minimize');
                });

                $('.js-sidebar-toggle-icon').css('transform', 'scale(1,1)');

                // 遅延表示で崩れを防ぐ
                setTimeout(function(){
                    $('.js-sidebar .js-sidebar-open.h1')
                        .removeClass('js-set-sidebar-hide')
                        .fadeIn(150)
                        .css('display', '');

                    $('.js-sidebar .js-sidebar-open.js-sidebar-child > li')
                        .removeClass('js-set-sidebar-hide')
                        .fadeIn(150)
                        .css('display', '');

                    $('.js-sidebar .js-tgt .js-sidebar-open')
                        .removeClass('js-set-sidebar-hide')
                        .fadeIn(150)
                        .css('display', '');

                    $('.js-sidebar .sidebar-sticky').removeClass('js-set-sidebar-minimize-sticky');
                    $('.js-sidebar .js-sidebar-open.sidebar-footer').show()

                }, setTimeoutSec - 20);

            } else {

                // SP版のJSアクション付与クラスを事前に削除
                $('.js-sidebar').removeClass('js-set-sidebar-open-sp');
                $('.js-sidebar-wrapper').removeClass('js-set-sidebar-open-sp');

                const setTimeoutSec = 300;
                const calcBaseWidth = 60;
                const resizeWidth = $('body').width() - calcBaseWidth;

                $('body').addClass('aside-minimize');

                $('.main-panel').animate({
                    width: resizeWidth
                }, setTimeoutSec, '', function() {
                    // js-set-main-panel-minimize => minimize.scss
                    $('.main-panel')
                        .css('width', '')
                        .addClass('js-set-main-panel-minimize');
                });

                $('.js-sidebar').animate({
                    width: calcBaseWidth
                }, setTimeoutSec, '', function() {
                    // js-set-sidebar-minimize => minimize.scss
                    $('.js-sidebar')
                        .css('width', '')
                        .addClass('js-set-sidebar-minimize');
                });

                $('.navbar.navbar-absolute').animate({
                    width: resizeWidth,
                    left:  calcBaseWidth
                }, setTimeoutSec, '', function() {
                    $('.navbar.navbar-absolute')
                        .css('width', '')
                        .css('left', '');
                    $('.navbar.navbar-absolute')
                        .removeClass('navbar-absolute')
                        .addClass('js-set-navbar-minimize');
                });

                $('.js-sidebar-toggle-icon').css('transform', 'scale(-1,1)');

                $('.js-sidebar .js-sidebar-open.h1').addClass('js-set-sidebar-hide');

                $('.js-sidebar .js-sidebar-open.js-sidebar-child > li').addClass('js-set-sidebar-hide');
                $('.js-sidebar .sidebar-sticky').addClass('js-set-sidebar-minimize-sticky');
                $('.js-sidebar .js-tgt .js-sidebar-open').addClass('js-set-sidebar-hide');

                $('.js-sidebar .js-sidebar-open.sidebar-footer').hide();

            }
        };

        $('.js-sidebar-toggle').click(function(){
            if ($('body').hasClass('aside-minimize')) {
                toggleSidebar(false);
            } else {
                toggleSidebar(true);
            }
        });

        $('.js-tgt').on('click', function () {
            if ($('body').hasClass('aside-minimize')) {
                toggleSidebar(false);
            }
        });

        // [Navbar] Search Form
        $('.js-search-container').focusin(function(e){
            e.stopPropagation();
            $(this).addClass('border-secondary');
            $(this).animate({
                width: 400
            }, 250, '', function() {
                $('.js-search-dropdown-toggle').dropdown('toggle').fadeIn(100);
            });
        }).focusout(function(e) {
            $('.js-search-dropdown-toggle').dropdown('toggle');
            $(this).removeClass('border-secondary')
            $(this).animate({
                width: 200
            }, 250);
        });

        $('.js-notification').click(function() {

            if ($('.js-notification').hasClass('menu-close-btn')) {

                $('.js-content').animate({
                    width: '+=350px'
                }, 250, '', function() {
                    $('.js-content')
                        .css('width', '')
                        .removeClass('js-set-notification-content');
                });

                // 非表示タイミングを調整しで崩れを防ぐ
                setTimeout(function(){
                    $('.js-notification-wrapper .card').addClass('d-lg-none')
                }, 100);

                $('.js-notification-wrapper').animate({
                    opacity: 'toggle'
                }, {
                    step: function(n) {
                        $(this).css({
                            '-webkit-transform': 'translateX(' + (100 - n * 100) + '%)',
                            'transform': 'translateX(' + (100 - n * 100) + '%)'
                        });
                    }
                });

                $('.js-notification').removeClass('menu-close-btn');
                $('.menu-btn').removeClass('active');

            } else {

                $('.js-content').animate({
                    width: '-=350px'
                }, 250, '', function() {
                    $('.js-content')
                        .css('width', '')
                        .addClass('js-set-notification-content');
                });

                $('.js-notification-wrapper').animate({
                    opacity: 'toggle'
                }, {
                    step: function(n) {
                        $(this).css({
                            '-webkit-transform': 'translateX(' + (100 - n * 100) + '%)',
                            'transform': 'translateX(' + (100 - n * 100) + '%)'
                        });
                    }
                });

                // 遅延表示で崩れを防ぐ
                setTimeout(function(){
                    $('.js-notification-wrapper .card').removeClass('d-lg-none');
                }, 300);

                $('.js-notification').addClass('menu-close-btn');
                $('.menu-btn').addClass('active');
            }
        });

        $('js-notification .card').hover(function() {
            $(this).animate()
        });

        (function() {
            const $body = $('body');
            const $sidebarToggle = $('.js-sidebar-toggle');

            // ブラウザリサイズ時処理
            var latestMediaType = null;
            var onResize = function() {
                var isMediaXl = (window.innerWidth >= 1200);
                var isMediaMd = (window.innerWidth >= 768);
                var currentMediaType = isMediaXl ? 'xl' : isMediaMd ? 'md' : '';

                // md 切り替え時 サイドバー隠す
                if (currentMediaType === 'md' && latestMediaType !== 'md' && !$body.hasClass('aside-minimize')) {
                    $sidebarToggle.trigger('click').trigger('blur');
                }

                latestMediaType = currentMediaType;
            };
            $(window).on('load resize', function() {
                window.requestAnimationFrame(onResize);
            });
            onResize();
        })();

    });

})();
