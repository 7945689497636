import 'bootstrap-select';
import PerfectScrollbar from 'perfect-scrollbar';

export default (function () {
    $(function () {
        var $document = $(document);

        $document.on({
            'rendered.bs.select': function() {
                var $select = $(this);
                var $toggle = $select.siblings('.dropdown-toggle');
                var $scroller = $select.siblings('.dropdown-menu').children('.inner');
                $toggle.append('<i class="fas fa-angle-down"></i>');
                var ps = new PerfectScrollbar($scroller.get(0));
            },
            'initValidation': function() {
                var $select = $(this);
                var $selectWrapper = $select.closest('.bootstrap-select');
                $select.on('change', function() {
                    var isValid = this.validity.valid;
                    $selectWrapper.toggleClass('is-valid', isValid).toggleClass('is-invalid', !isValid);
                }).trigger('change');
            }
        }, 'select');

        $('select').selectpicker();

        // Data Tables
        $document.on('init.dt', function (e, settings) {
            var api = new $.fn.dataTable.Api(settings);
            var $wrapper = $(api.context[0].nTableWrapper);
            $wrapper.find('select').selectpicker();
        });
    });
})();
