export default (function () {

    $(function(){
        // initialize
        $('[data-toggle="popover"]').popover();

        //Dismiss on next click
        $('.popover-dismiss').popover({
            trigger: 'focus'
        })
    });

})();
