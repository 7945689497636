window.jQuery = $;
window.$ = $;

// Bootstrap/JavaScript
import "bootstrap";
import 'bootstrap-notify/bootstrap-notify.js';
import './custom/components/tooltips.js'
import './custom/components/popovers.js'

import 'jquery.cookie/jquery.cookie.js';

// font-awesome
import '@fortawesome/fontawesome-free/js/all'

// etc
import 'perfect-scrollbar/dist/perfect-scrollbar.min.js'

// Symphonict Template
import './custom/template_common.js'
import './custom/template_pc.js'
import './custom/template_sp.js'

// Symphonict Template (Document)
import './custom/document.js'

// Symphonict Customize
import './custom/apexcharts.js'
import './custom/bootstrap-select.js'
import './custom/inputmask.js'
import './custom/bootstrap-daterangepicker.js'

/* datatable */
import './custom/jquery.dataTables.min.js'
import './custom/dataTables.bootstrap4_customized.js'

// // bootstrap table
import 'bootstrap-table/dist/bootstrap-table.min.js'

// Svgxuse
import 'svgxuse/svgxuse.min.js'

// Picturefill
import 'picturefill/dist/picturefill.min.js'

///////////////////////////////////////////////
// StyleSheet
import "../scss/symphonict-platform.scss";
