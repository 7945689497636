// Inputmask
import "inputmask/dist/inputmask.js";
import "inputmask/dist/jquery.inputmask.js";
import "polyfill-array-includes";

export default (function () {

    $(function(){

        var NeedsInputmask = function () {

            // Private functions
            var demos = function () {
                // date format
                $(".needs_inputmask_1").inputmask("99/99/9999", {
                    "placeholder": "mm/dd/yyyy",
                    autoUnmask: true
                });

                // custom placeholder
                $(".needs_inputmask_2").inputmask("99/99/9999", {
                    "placeholder": "mm/dd/yyyy",
                });

                // phone number format
                $(".needs_inputmask_3").inputmask("mask", {
                    "mask": "(999) 9999-9999"
                });

                // empty placeholder
                $(".needs_inputmask_4").inputmask({
                    "mask": "99-9999999",
                    placeholder: "" // remove underscores from the input mask
                });

                // repeating mask
                $(".needs_inputmask_5").inputmask({
                    "mask": "9",
                    "repeat": 10,
                    "greedy": false
                }); // ~ mask "9" or mask "99" or ... mask "9999999999"

                // decimal format
                $(".needs_inputmask_6").inputmask('decimal', {
                    rightAlignNumerics: false
                });

                // currency format
                $(".needs_inputmask_7").inputmask('€ 999.999.999,99', {
                    numericInput: true
                }); //123456  =>  € ___.__1.234,56

                //ip address
                $(".needs_inputmask_8").inputmask({
                    "mask": "999.999.999.999"
                });

                //email address
                $(".needs_inputmask_9").inputmask({
                    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
                    greedy: false,
                    onBeforePaste: function (pastedValue, opts) {
                        pastedValue = pastedValue.toLowerCase();
                        return pastedValue.replace("mailto:", "");
                    },
                    definitions: {
                        '*': {
                            validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
                            cardinality: 1,
                            casing: "lower"
                        }
                    }
                });
            }

            return {
                // public functions
                init: function() {
                    demos();
                }
            };
        }();

        jQuery(document).ready(function() {
            NeedsInputmask.init();
        });

    });

})();
